define("plusdin/tailwind/config", [], function () {
  "use strict";

  /*global module*/

  /*global require*/
  module.exports = {
    separator: ':',
    theme: {
      screens: {
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px'
      },
      colors: {
        magenta: '#F0F',
        transparent: 'transparent',
        black: '#000',
        white: '#fff',
        pink: {
          light: '#E5386D',
          dark: '#95143C'
        },
        blue: {
          light: '#48AAF3',
          lighten: '#2D7BB4',
          dark: '#24326B',
          darken: '#151B35'
        },
        grey: {
          light: '#EAEAEA',
          dark: '#7E838E'
        },
        red: '#FF4459',
        green: '#48BB78'
      },
      fontSize: {
        tiny: '.65rem',
        xs: '.75rem',
        sm: '.875rem',
        base: '1rem',
        lg: '1.125rem',
        xl: '1.25rem',
        '2xl': '1.5rem',
        '3xl': '1.875rem',
        '4xl': '2.25rem',
        '5xl': '3rem',
        '6xl': '4rem'
      },
      fontFamily: {
        'opensans': ['Open Sans'],
        'roboto': ['Roboto']
      }
    },
    variants: {
      opacity: ['responsive', 'hover', 'focus', 'disabled']
    },
    plugins: [require("tailwindcss-transitions")(), require("tailwindcss-responsive-embed")(), require("tailwindcss-aspect-ratio")({
      ratios: {
        '4/3': [4, 3]
      }
    })]
  };
});