define("plusdin/initializers/hotjar-analytics", ["exports", "plusdin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    if (_environment.default.environment !== 'production') {
      return;
    }

    if (typeof document === 'undefined') {
      return;
    }

    window.hj = window.hj || function () {
      (window.hj.q = window.hj.q || []).push(arguments);
    };

    window._hjSettings = {
      hjid: 1802358,
      hjsv: 6
    };
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.async = 1;
    script.src = "https://static.hotjar.com/c/hotjar-".concat(window._hjSettings.hjid, ".js?sv=").concat(window._hjSettings.hjsv);
    head.appendChild(script);
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});