define("plusdin/initializers/google-analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    if (typeof document === 'undefined') {
      return;
    }

    var ga = document.createElement('script');
    ga.type = 'text/javascript';
    ga.async = true;
    ga.src = '//www.googletagmanager.com/gtag/js?id=UA-77843728-12';
    var script = document.getElementsByTagName('script')[0];
    script.parentNode.insertBefore(ga, script);
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', 'UA-77843728-12', {
      custom_map: {
        dimension16: 'dimension_negativado',
        dimension28: 'dimension_emprestimo_tipo',
        dimension29: 'dimension_emprestimo_valor',
        dimension30: 'dimension_emprestimo_prazo',
        dimension4: 'dimension_final'
      },
      optimize_id: 'OPT-TQZB43K',
      cookie_flags: 'SameSite=None;Secure;'
    }); // analytics

    gtag('config', 'AW-741735734'); // google ads

    gtag('config', 'AW-972903248'); // MCC

    window.gtag = gtag;
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});