define("plusdin/initializers/hotjar", ["exports", "plusdin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    if (_environment.default.environment !== 'production') {
      return;
    }

    if (typeof document === 'undefined') {
      return;
    }

    var hotjar = document.createElement('script');
    hotjar.type = 'text/javascript';
    hotjar.async = true;
    hotjar.innerHTML = "\n  (function(h,o,t,j,a,r){\n      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n      h._hjSettings={hjid:1578388,hjsv:6};\n      a=o.getElementsByTagName('head')[0];\n      r=o.createElement('script');r.async=1;\n      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n      a.appendChild(r);\n  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');\n  ";
    var script = document.getElementsByTagName('script')[1];
    script.parentNode.insertBefore(hotjar, script);
    console.log('hotjar');
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});