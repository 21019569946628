define("plusdin/initializers/taboola-pixel", ["exports", "plusdin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    if (_environment.default.environment !== 'production') {
      return;
    }

    if (typeof document === 'undefined') {
      return;
    }

    window._tfa = window._tfa || [];

    window._tfa.push({
      notify: 'event',
      name: 'page_view',
      id: 1291480
    });

    !function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    }(document.createElement('script'), document.getElementsByTagName('script')[0], '//cdn.taboola.com/libtrc/unip/1291480/tfa.js', 'tb_tfa_script');
    var pixelImage = document.createElement('img');
    pixelImage.src = 'https://trc.taboola.com/1291480/log/3/unip?en=page_view';
    pixelImage.height = 0;
    pixelImage.width = 0;
    pixelImage.style.cssText = 'display:none;visibility:hidden';
    document.body.appendChild(pixelImage);
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});