define("plusdin/templates/components/group-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BYiqrxNu",
    "block": "{\"symbols\":[\"@groupName\"],\"statements\":[[9,\"input\",false],[14,\"name\",[27,[24,1],[]],null],[14,\"id\",[27,[24,0],[\"checkboxId\"]],null],[14,\"value\",[27,[24,0],[\"value\"]],null],[14,\"checked\",[27,[24,0],[\"checked\"]],null],[23,\"class\",\"custom-checkbox\",null],[23,\"type\",\"checkbox\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[\"change\",[27,[24,0],[\"inputChanged\"]]],null],[10],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "plusdin/templates/components/group-checkbox.hbs"
    }
  });

  _exports.default = _default;
});