define("plusdin/components/svg-radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    className="svg"
    fill="currentColor"
    preserveAspectRatio="xMidYMid meet"
    height="20"
    width="20"
    viewBox="0 0 20 20"
  >
    <circle
      className="radioOutline"
      cx="10"
      cy="10"
      r="8"
      fill="none"
      stroke="black"
      strokeWidth="3"
    />
  </svg>
  
  */
  {
    id: "pkx46YUO",
    block: "{\"symbols\":[],\"statements\":[[9,\"svg\",true],[12,\"className\",\"svg\",null],[12,\"fill\",\"currentColor\",null],[12,\"preserveAspectRatio\",\"xMidYMid meet\",null],[12,\"height\",\"20\",null],[12,\"width\",\"20\",null],[12,\"viewBox\",\"0 0 20 20\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"circle\",true],[12,\"className\",\"radioOutline\",null],[12,\"cx\",\"10\",null],[12,\"cy\",\"10\",null],[12,\"r\",\"8\",null],[12,\"fill\",\"none\",null],[12,\"stroke\",\"black\",null],[12,\"strokeWidth\",\"3\",null],[10],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "plusdin/components/svg-radio-button.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});